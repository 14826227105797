import { Component, OnInit, ViewChild,ChangeDetectorRef, ViewContainerRef, ComponentFactoryResolver,  ComponentRef,Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormsService } from 'src/services-generated/api/forms.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PicFormComponent } from '../pic-form/pic-form.component';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pic-main',
  templateUrl: './pic-main.component.html',
  styleUrls: ['./pic-main.component.scss'],
  providers: [DatePipe],
})
export class PicMainComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild('pdfHost', { read: ViewContainerRef, static: false }) pdfHost!: ViewContainerRef;

  displayedColumns: string[] = ['name', 'sentinelNumber', 'formSubmittedOn', 'action'];
  users: MatTableDataSource<any> = new MatTableDataSource<any>();
  loading = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private formsService: FormsService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.fetchPicFormUserDetails();
  }

  fetchPicFormUserDetails() {
    this.loading = true;
    this.formsService.getPicFormUserDetails().subscribe({
      next: (data) => {
        data.forEach(user => {
          if (user.formSubmittedOn) {
            user.formSubmittedOn = this.datePipe.transform(user.formSubmittedOn, 'dd-MM-yyyy HH:mm');
          }
        });
        this.users = new MatTableDataSource(data);
        this.users.paginator = this.paginator;
        this.loading = false;
      },
      error: (error) => {
        this.snackBar.open('Failed to fetch PicForm details.', 'Close', {
          duration: 3000,
        });
        this.loading = false;
      },
    });
  }

  /**
   * Apply a filter to the data table.
   * @param filterValue The value to filter the table.
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.users.filter = filterValue;
  }

  /**
   * Opens the form in a dialog for viewing/editing.
   * @param sentinelNumber The Sentinel Number associated with the form.
   */
  openForm(sentinelNumber: string): void {
    const dialogRef = this.dialog.open(PicFormComponent, {
      width: '900px',
      height: '90vh',
      data: { sentinelNumber },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Dialog was closed with data:', result);
      }
    });
  }

  
  downloadForm(sentinelNumber: string): void {
    this.formsService.getPicFormData(sentinelNumber).subscribe(
      (responseData) => {
        this.pdfHost.clear();
  
        const injector = Injector.create({
          providers: [{ provide: MAT_DIALOG_DATA, useValue: responseData }],
          parent: this.pdfHost.injector,
        });
  
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PicFormComponent);
        const componentRef: ComponentRef<PicFormComponent> = this.pdfHost.createComponent(componentFactory, 0, injector);
        this.cdr.detectChanges();
  
        const hiddenContainer = document.createElement('div');
        hiddenContainer.style.position = 'absolute';
        hiddenContainer.style.left = '-9999px';
        hiddenContainer.style.width = '210mm'; 
        hiddenContainer.style.minHeight = '297mm'; 
        hiddenContainer.style.overflow = 'hidden';
        hiddenContainer.style.fontSize = '12px'; 
        hiddenContainer.style.lineHeight = '1.5'; 
        hiddenContainer.style.padding = '10mm'; 
        document.body.appendChild(hiddenContainer);
        hiddenContainer.appendChild(componentRef.location.nativeElement);
  
        setTimeout(() => {
          html2canvas(hiddenContainer, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 0.5);
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgProps = pdf.getImageProperties(imgData);
            const imgWidth = pdfWidth - 20;
            const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
            let position = 10;
            let heightLeft = imgHeight;
  
            while (heightLeft > 0) {
              pdf.addImage(imgData, 'PNG', 10, position, imgWidth, Math.min(imgHeight, pdfHeight - 20));
              heightLeft -= pdfHeight - 20;
              position -= pdfHeight - 20;
              if (heightLeft > 0) pdf.addPage();
            }
  
            const fileName = `PICForm_${sentinelNumber}_${this.datePipe.transform(new Date(), 'dd-MM-yyyy')}.pdf`;
            pdf.save(fileName);
  
            document.body.removeChild(hiddenContainer);
            this.pdfHost.clear();
          }).catch((error) => {
            console.error('Error generating PDF:', error);
          });
        }, 500);
      },
      (error) => {
        console.error('Error fetching PIC form details:', error);
      }
    );
  }
  
  
}
