import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  public basePath = environment.basePath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject('BASE_PATH') basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Fetches details of users whose PicForm has been submitted.
   * @returns Observable with the user details.
   */
  public getPicFormUserDetails(): Observable<any> {
    return this.httpClient.get(`${this.basePath}/api/Forms/GetPicFormUserDetails`, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }

  /**
   * Fetches the PicForm data for a specific SentinelNumber.
   * @param sentinelNumber The sentinel number of the operative.
   * @returns Observable with the PicForm data.
   */
  public getPicFormData(sentinelNumber: string): Observable<any> {
    return this.httpClient.get(`${this.basePath}/api/Forms/GetPicFormData/${sentinelNumber}`, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }
  public getAllPicFormData(): Observable<any> {
    return this.httpClient.get(`${this.basePath}/api/Forms/AllPicFormData/picForm`, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }
   /**
   * Fetches all PPE Forms.
   * @returns Observable with the list of PPE forms.
   */
   public getPpeForms(): Observable<any> {
    return this.httpClient.get(`${this.basePath}/api/Forms/GetPpeForms`, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }

  /**
   * Fetches PPE Form details for a specific ID.
   * @param id The ID of the PPE form.
   * @returns Observable with the PPE form details.
   */
  public getPpeFormDetails(id: number): Observable<any> {
    return this.httpClient.get(`${this.basePath}/api/Forms/GetPpeFormDetails/${id}`, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }
  public getAllPpeFormData(): Observable<any> {
    return this.httpClient.get(`${this.basePath}/api/Forms/AllPpeFormData/ppeForm`, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }

  public getPpeItemList(): Observable<any> {
    return this.httpClient.get(`${this.basePath}/api/Forms/GetItems`, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }

  public addPpeItem(itemName: string): Observable<any> {
    const body = { itemName };
    return this.httpClient.post(`${this.basePath}/api/Forms/AddItem`, body, {
      headers: this.defaultHeaders,
      withCredentials: this.configuration.withCredentials,
    });
  }

  public deletePpeItem(itemId: number): Observable<any> {
    return this.httpClient.post(
      `${this.basePath}/api/Forms/DeletePpeItem?itemId=${itemId}`,null, 
      {
        headers: this.defaultHeaders,
        withCredentials: this.configuration.withCredentials,
      }
    );
  }  

}