import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import * as XLSX from "xlsx";
import { ReportService } from 'src/services-generated';
import { FormsService } from 'src/services-generated/api/forms.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
  })
export class AdminComponent implements OnInit {
    isLoading: boolean = false;

    constructor(private reportService: ReportService,
      private formsService: FormsService
    ) { }

    ngOnInit() {
    }

    exportExcelReport(){
      this.isLoading = true; 
      this.reportService.documentTypeDownloadGet().subscribe(response => {
        let date = new Date();
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url= window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `document_report_${date}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.isLoading = false;
      },
      error => {
        console.error("Error while downloading the report:", error);
        this.isLoading = false; // Hide loader in case of error
      })
    }


    exportExcelPic(): void {
      this.formsService
        .getAllPicFormData().subscribe(
        (data) => {
          const picForm = data.result; 
          this.exportAsExcelFile(picForm, "PIC_Form_Data");
        },
        (error) => {
          console.error("Error occurred:", error);
        }
      );
    }
     exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
      }

    exportRulebooksReport() { 
      this.reportService.rulebooksReportDownload().subscribe(response => {
          const date = new Date();
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = `Rulebooks_Report_${date.toISOString().split('T')[0]}.csv`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
      }, error => {
          console.error('Error downloading rulebooks report:', error);
      });
  }
}