import { Component, OnInit , Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsService } from 'src/services-generated/api/forms.service';

@Component({
  selector: 'app-pic-form',
  templateUrl: './pic-form.component.html',
  styleUrls: ['./pic-form.component.scss']
})
export class PicFormComponent implements OnInit {
  formData: any = {}; 
  sentinelNumber: string; 
  loading = false; 

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, 
  private formsService: FormsService){}
  ngOnInit() {
    this.sentinelNumber = this.data.sentinelNumber;
    if (this.sentinelNumber) {
      this.fetchFormData();
    }
  }

  fetchFormData() {
    this.loading = true;
    this.formsService.getPicFormData(this.sentinelNumber).subscribe(
      (data) => {
        this.formData = data; 
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching form data:', error);
        this.loading = false;
      }
    );
  }
}
