import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormsService } from 'src/services-generated/api/forms.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ppe-item-list',
  templateUrl: './ppe-item-list.component.html',
  styleUrls: ['./ppe-item-list.component.scss']
})
export class PpeItemListComponent implements OnInit {

  newItemName: string = ''; 
  ppeItems: any[] = [];
  displayedColumns: string[] = ['serialNumber', 'itemName','actions']; 
  dataSource = new MatTableDataSource<any>([]); 
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  constructor(
    private formsService: FormsService,
  ) { }

  ngOnInit() {
    this.getPpeItems();
  }

  getPpeItems() {    
    this.formsService.getPpeItemList().subscribe({
      next: (response) => {
        if (response && response.isSuccessful && Array.isArray(response.result)) {
          this.dataSource.data = response.result.map((item, index) => ({
            ...item,
            serialNumber: index + 1 
          }));
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          console.error('Unexpected API response:', response);
        }
      },
      error: (error) => {
        console.error('Error fetching PPE items:', error);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


addNewItem() {   
  const trimmedName = this.newItemName.trim();      
  if (!trimmedName) {
    Swal.fire({
      icon: 'warning',
      title: 'Invalid Input',
      text: 'Please enter a valid item name!',
      confirmButtonText: 'OK'
    });
    return;
  }    
  
  this.formsService.addPpeItem(trimmedName).subscribe({
    next: (response) => {
      if (response.isSuccessful) {
        // Success case
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Item added successfully!',
          confirmButtonText: 'OK',    
          confirmButtonColor: '#EB4000',      
        });
        this.newItemName = ''; 
        this.getPpeItems(); 
      } else {        
        Swal.fire({
          icon: 'warning',
          title: 'Duplicate Item',
          text: response.message, 
          confirmButtonText: 'OK',
          confirmButtonColor: '#EB4000',
        });
      }
    },
    error: (error) => {
      console.error('Error adding new item:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to add the item. Please try again.',
        confirmButtonText: 'OK',
        confirmButtonColor: '#EB4000',
      });
    }
  });
}

deleteItem(itemId: number, itemName: string) {
  Swal.fire({
    title: `Are you sure you want to delete "${itemName}"?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#EB4000',
    cancelButtonColor: '#d33'
  }).then((result) => {
    if (result.isConfirmed) {
      this.formsService.deletePpeItem(itemId).subscribe({
        next: (response) => {
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: response.message,
            confirmButtonColor: '#EB4000'
          });
          this.getPpeItems();
        },
        error: (error) => {
          console.error('Error deleting item:', error);
          Swal.fire({
            icon: 'error',
            title: 'Failed!',
            text: 'Could not delete the item. Please try again.',
            confirmButtonColor: '#EB4000'
          });
        }
      });
    }
  });
}

}
