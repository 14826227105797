import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AccountService } from 'src/services-generated';
import { User } from 'src/services-generated/api/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-locked-operatives',
  templateUrl: './locked-operatives.component.html',
  styleUrls: ['./locked-operatives.component.scss']
})
export class LockedOperativesComponent implements OnInit {

  displayedColumns: string[] = [
    'sentinelNumber',
    'name',
    'lastname',
    'email',
    'phoneNumber',
    'homePostCode',
    'businessDeliveryUnit',
    'action'
  ];

  siteOperatives = new MatTableDataSource<User>([]);
  
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(private accountService: AccountService) { }

  ngOnInit() {
    this.loadLockedOperatives();

    this.siteOperatives.filterPredicate = (data: User, filter: string) => {
      const fullName = `${data.firstName} ${data.lastName}`.toLowerCase(); 
      const email = data.email.toLowerCase();
      const sentinel = data.siteOperativeDetail.sentinelNumber.toLowerCase();
      const businessUnit = data.siteOperativeDetail.businessDeliveryUnit.toLowerCase();
      const phoneNumber = data.phoneNumber.toLowerCase();      
  
      return (
        fullName.includes(filter) || 
        email.includes(filter) || 
        sentinel.includes(filter) || 
        businessUnit.includes(filter) ||
        phoneNumber.includes(filter)
      );
    };
  }
  
  loadLockedOperatives(): void {
    this.accountService.getLockedOperatives().subscribe(
      (response) => {
        if (response.isSuccessful) {
          this.siteOperatives.data = response.result; 
          this.siteOperatives.paginator = this.paginator;
          this.siteOperatives.sort = this.sort;
        } else {
          console.warn("API Error:", response.message);
          this.siteOperatives.data = []; 
        }
      },
      (error) => {
        console.error("Error fetching locked site operatives:", error);
        this.siteOperatives.data = []; 
      }
    );
  }
  
 
  applyFilter(filterValue: string): void {
    this.siteOperatives.filter = filterValue.trim().toLowerCase();
  }
 
   unlockUser(user: User): void {
     Swal.fire({
       title: `Confirm Unlock: ${user.firstName} ${user.lastName}`,
       text: "Are you sure you want to unlock this account?",
       icon: 'warning',
       showCancelButton: true,
       confirmButtonText: 'Yes, unlock it!',
       cancelButtonText: 'No, cancel!',
       reverseButtons: true
     }).then((result) => {
       if (result.isConfirmed) {        
         this.accountService.unlockUser(user.siteOperativeDetail.userId).subscribe(
           () => {
             Swal.fire(
               'Unlocked!',
               `${user.firstName} ${user.lastName}'s Account has been unlocked.`,
               'success'
             );
             this.loadLockedOperatives(); 
           },
           (error) => {
             console.error('Error unlocking user:', error);
             Swal.fire(
               'Error!',
               'There was an error unlocking the user.',
               'error'
             );
           }
         );
       } else if (result.dismiss === Swal.DismissReason.cancel) {
         Swal.fire(
           'Cancelled',
           `${user.firstName} ${user.lastName}'s Account is not unlocked.`,
           'info'
         );
       }
     });
   }

  
}
