import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsService } from 'src/services-generated/api/forms.service';

@Component({
  selector: 'app-ppe-forms',
  templateUrl: './ppe-forms.component.html',
  styleUrls: ['./ppe-forms.component.scss'],
})
export class PPEFormsComponent implements OnInit {
  formData: any = {}; 
  id:number; 
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formsService: FormsService
  ) {}

  ngOnInit() {
    this.id = this.data.id;
    if (this.id) {
      this.fetchPpeFormData();
    }
  }


  fetchPpeFormData( ) {
    this.loading = true;
    this.formsService.getPpeFormDetails(this.id).subscribe({
      next: (response) => {
        if (response.result) {
          this.formData = response.result;
        } else {
          console.error('Invalid response format');
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching PPE form details:', error);
        this.loading = false;
      }
    });
  }
}
