import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SiteApiService } from 'src/services-generated/api/site-api.service';
import { SiteModelPost } from 'src/services-generated/model/SiteModelPost';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-new-edit-site',
  templateUrl: './add-new-edit-site.component.html',
  styleUrls: ['./add-new-edit-site.component.scss']
})
export class AddNewEditSiteComponent implements OnInit {
  loading = false;
  SiteDetail = new FormGroup({
    name: new FormControl(''),
    location: new FormControl(''),
    secondaryLocation: new FormControl(''),
    infrastructureId: new FormControl(''),
    postalCode: new FormControl(''),

  })
  SelectedSiteName: any;
  SelectedPrimaryLocation: any;
  SelectedSecondaryLocation: any;
  SelectedInfrastructureId: any;
  SelectedPostalCode: any;
  snackBar: any;
  SelectedId: any;
  constructor(private SiteApiService: SiteApiService, private router: Router, private formBuilder: FormBuilder,) { }

  ngOnInit() {
    var selectedSiteDetails = localStorage.getItem('selectedSiteDetails');
    var element = JSON.parse(selectedSiteDetails);
    this.SelectedId = element.id
    this.SelectedSiteName = element.name;
    this.SelectedPrimaryLocation = element.location;
    this.SelectedSecondaryLocation = element.secondaryLocation;
    this.SelectedInfrastructureId = element.infrastructureId;
    this.SelectedPostalCode = element.postalCode;
    this.SiteDetail = new FormGroup({
      SelectedSiteName: new FormControl(element['name']),
      SelectedPrimaryLocation: new FormControl(element['location']),
      SelectedSecondaryLocation: new FormControl(element['secondaryLocation']),
      SelectedInfrastructureId: new FormControl(element['infrastructureId']),
      SelectedPostalCode: new FormControl(element['postalCode']),
    })
    console.log('sites', element)
    console.log('SiteDetail', this.SiteDetail)

  }
  onSubmit() {    
    let Details: SiteModelPost = {
      name: this.SiteDetail.value.SelectedSiteName, // use 'name' instead of 'SelectedSiteName'
      location: this.SiteDetail.value.SelectedPrimaryLocation,
      secondaryLocation: this.SiteDetail.value.SelectedSecondaryLocation,
      infrastructureId: this.SiteDetail.value.SelectedInfrastructureId,
      postalCode: this.SiteDetail.value.SelectedPostalCode,

    };
    console.log('SiteDetail', Details)
    this.loading = true;
    this.SiteApiService.apiSiteEditIdPut(this.SelectedId, Details).subscribe((siteResponse) => {
      console.log('EditedDetails', siteResponse)
      this.loading = false;
      if (siteResponse.isSuccessful = true) {
        Swal.fire({
          icon: 'success',
          title: 'Edited successfully',
          text: siteResponse["Message"],
        }).then((res) => {
          // Fetch the updated table data and update the table
          this.router.navigate(['../supervisor/SiteList']);
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: 'failed!',
          text: siteResponse["Message"],
        })
      }
    },
      (error) => {
        console.log(error);
        this.snackBar.open(error, "OK", {
          duration: 3000,
        });
        this.loading = false;

      })
  }

  goBack(){
    var data = localStorage.getItem("SelectedOperativeId");
    this.router.navigate([
      "/supervisor/sitefamiliarisationPost",
      { id: data },
    ]);
  }
}